import { Stack } from '@mui/material';
import { Logo } from "./components/Logo";

export const ComingSoonPage = () => {
  return (
    <Stack
      style={{
        maxWidth: "1420px",
        margin: "0 auto",
      }}
    >
      <Logo />
      <div style={{ textAlign: "center", fontSize: "1.2em", margin: "10px 50px" }}>Your personal sleep assistant</div>
    </Stack>
  );
};