import { ComingSoonPage } from './pages/coming_soon/ComingSoonPage';

function App() {
  return (
    <>
      <ComingSoonPage />
    </>
  );
}

export default App;
