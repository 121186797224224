import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./normalize.css";
import { ThemeProvider, createTheme } from "@mui/material";

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Nunito Sans",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeightRegular: 300,
    h2: {
      fontWeight: 500,
    },
  },
});

function ExternalRedirect({ to }: { to?: string }) {
  if (to) {
    window.location.href = to;
  }
  return null;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/contact-us",
    element: <ExternalRedirect to={process.env.REACT_APP_CONTACT_FORM_URL} />,
  },
  {
    path: "/privacy-policy",
    element: <ExternalRedirect to={process.env.REACT_APP_PRIVACY_POLICY_URL} />,
  },
  {
    path: "/terms-of-service",
    element: (
      <ExternalRedirect to={process.env.REACT_APP_TERMS_OF_SERVICE_URL} />
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
